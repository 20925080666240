import React from "react";
import { Tooltip, Pane } from "evergreen-ui";
import Navigator from "@components/Navigator";
import Image from "next/image";
import NavigationMenu from "@components/NavigationMenu";
import SearchBox from "@components/Searchbox";
import { useTheme } from "next-themes";
import ThemeToggle from "@components/ThemeToggle";
import I18NToggle from "@components/I18NToggle";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import ShareCard from "@components/ShareCard";
import Link from "next/link";

const ignoreSharePathName = ["/", "/404", "/500", '/compress-image', '/qrcode'];

export default function App({ children }: { children: React.ReactNode }) {
  const { theme } = useTheme();
  const { t } = useTranslation("common");
  const router = useRouter();

  return (
    <>
      <Pane
        display="flex"
        alignItems="center"
        flexDirection="row"
        is="header"
        height={50}
        className="supports-backdrop-blur:bg-background/60 sticky top-0 z-10 w-full border-b bg-background/95 backdrop-blur"
        paddingRight={20}
      >
        <Link
          href="/"
          prefetch={false}
          style={{
            textDecoration: "none"
          }}
        >
          <Pane
            display="flex"
            alignItems="center"
            paddingX={20}
            className="py-2 text-sm font-medium logo-transform"
          >
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX={10}
            >
              <Image
                width={30}
                height={30}
                alt={`${t("slogan")}`}
                src="/static/tool-logo.png"
              />
            </Pane>
            <span className="colorful">Tool</span>
            <span className="hidden md:flex overflow-hidden text-ellipsis whitespace-nowrap max-w-[240px]">
              &nbsp;-&nbsp;{t("slogan")}
            </span>
          </Pane>
        </Link>
        <div className="flex-1 items-center px-5 hidden md:flex">
          <NavigationMenu />
        </div>

        <Pane
          display="flex"
          paddingX={15}
          alignItems="center"
          className="tool-pane"
        >
          <SearchBox />
        </Pane>

        <Pane display="flex" height={"100%"} alignItems={"center"} paddingX={5}>
          <Tooltip content={t("action.toggle-theme")}>
            <ThemeToggle />
          </Tooltip>
        </Pane>
        <Pane display="flex" height={"100%"} alignItems={"center"} paddingX={5}>
          <Tooltip content={t("action.toggle-locale")}>
            <I18NToggle />
          </Tooltip>
        </Pane>
      </Pane>
      {(router.pathname.includes("-to-") || router.pathname.includes("-format")) ? (
        <Pane
          backgroundColor="#FFFFFF"
          className={"tool-pane"}
          display="flex"
          flexDirection="row"
        >
          <Navigator />
          {children}
        </Pane>
      ) : (
        <Pane
          className={"normal-pane overflow-x-hidden overflow-y-auto"}
          display="flex"
          flexDirection="column"
          height={"calc(100vh - 50px)"}
        >
          <Pane
            is="main"
            className="w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8"
          >
            <Pane is="div" className="flex p-1 md:p-6">
              <div className="w-full">{children}</div>
              {!ignoreSharePathName.includes(router.pathname) && (
                <div className="hidden lg:block inset-0 w-[32rem] pb-10 pl-6 overflow-y-auto">
                  <ShareCard />
                </div>
              )}
            </Pane>
          </Pane>
        </Pane>
      )}
    </>
  );
}
