import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import "@styles/main.css";
import "@styles/global.css";

import NProgress from "nprogress";
import Router, { useRouter } from "next/router";
import { activeRouteData } from "@utils/routes";
import { Meta } from "@components/Meta";
import { Analytics } from "@vercel/analytics/react";
import Layout from "@/components/layouts/normal";
import { ThemeProvider } from "@/components/theme-provider";
import { appWithTranslation } from "next-i18next";
import { useTranslation } from "next-i18next";
import { GoogleAnalytics } from "@next/third-parties/google";
import MicrosoftClarity from "@/components/MicrosoftClarity";
import { SpeedInsights } from "@vercel/speed-insights/next";

function _Meta() {
  const router = useRouter();
  const { t } = useTranslation("common");

  const activeRoute = activeRouteData(router.pathname);

  return router.pathname === "/" || !router.pathname ? (
    <Meta
      title={t("title")}
      url={`https://douni.one${
        router.locale === "en" ? "" : `/${router.locale}`
      }${router.pathname}`}
      description={t("slogan")}
      i18N={false}
    />
  ) : (
    <Meta
      title={activeRoute?.searchTerm}
      url={`https://douni.one${
        router.locale === "en" ? "" : `/${router.locale}`
      }${router.pathname}`}
      description={activeRoute?.desc}
      i18N
    />
  );
}

function App(props: AppProps) {
  const router = useRouter();

  useEffect(() => {
    let timer;

    const stopProgress = () => {
      clearTimeout(timer);
      NProgress.done();
    };

    const startProgress = () => NProgress.start();

    const showProgressBar = () => {
      timer = setTimeout(startProgress, 300);
      router.events.on("routeChangeComplete", stopProgress);
      router.events.on("routeChangeError", stopProgress);
    };

    router.events.on("routeChangeStart", showProgressBar);

    return () => {
      router.events.off("routeChangeComplete", stopProgress);
      router.events.off("routeChangeError", stopProgress);
      router.events.off("routeChangeStart", showProgressBar);
      timer && clearTimeout(timer);
    };
  }, []);

  const { Component, pageProps } = props;

  return (
    <>
      <_Meta />
      <GoogleAnalytics gaId="G-YEGZTGTRY0" />
      {process.env.NODE_ENV !== "development" && <MicrosoftClarity />}
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        // enableSystem
        disableTransitionOnChange
      >
        <Layout>
          <Component {...pageProps} />
          <Analytics />
          <SpeedInsights />
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default appWithTranslation(App);
