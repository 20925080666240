import { useTranslation } from "next-i18next";
import Head from "next/head";
import React from "react";

const DEFAULT_KEYWORDS =
  "Online Transform Tools,Base64 Encoding/Decoding,Transform Tools,Developer Tools,JSON Formatting,Image Compression,Image Cropping,URL Encode/Decode";
export const Meta = ({
  title: _title,
  description,
  url,
  i18N,
}: {
  title?: string;
  description: string;
  url: string;
  i18N?: boolean;
  canonicalURL?: string;
}) => {
  const { t } = useTranslation("description");
  const { t: tt } = useTranslation("title");
  const { t: tk } = useTranslation("keywords");
  const title = i18N ? tt(description) : _title;
  const keywords = i18N ? tk(description) : DEFAULT_KEYWORDS;

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/static/favicon.png" type="image/png" />
      <meta content={i18N ? t(description) : description} name="description" />
      <meta name="keywords" content={keywords} />
      <meta name="og:url" content={url} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={"https://tool.douni.one/cover.png"} />
      <meta name="og:type" content="website" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={i18N ? t(description) : description}
      />
      <meta name="twitter:image" content={"https://tool.douni.one/cover.png"} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@koyaguo" />
      <link rel="manifest" href="/static/site.webmanifest" />
      {url && <link rel="canonical" href={url} />}
    </Head>
  );
};
