import React, { useEffect, useState } from "react";

import { toaster, Pane } from "evergreen-ui";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import copy from "clipboard-copy";
import { Copy, FacebookIcon, TwitterIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import clsx from "clsx";

export default function ShareCard({ isNavigator }: { isNavigator?: boolean }) {
  const { t } = useTranslation("common");
  const { t: td } = useTranslation("description");

  const router = useRouter();
  const [baseUrl, setBaseUrl] = useState(process.env.VERCEL_URL || "");

  useEffect(() => {
    if (window.location) {
      setBaseUrl(window.location.origin);
    }
  }, []);

  const onShare = ({ type }: { type: "x" | "facebook" }) => {
    const path = `${router.locale === "en" ? "" : `/${router.locale}`}${
      router.pathname
    }`;
    const _path = router.pathname.replace("/", "");
    const shareText = td(_path) !== _path ? td(_path) : t("slogan");
    let link = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      `${baseUrl}${path}`
    )}`;
    if (type === "x") {
      link = `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
        `${baseUrl}${path}`
      )}`;
    }

    window.open(link);
  };

  const onCopy = () => {
    const path = `${router.locale === "en" ? "" : `/${router.locale}`}${
      router.pathname
    }`;
    copy(`${baseUrl}${path}`);
    toaster.success(t("editor.copy-message"));
  };

  return (
    <Card className={"w-full"}>
      <CardHeader
        className={clsx({
          "p-3": isNavigator
        })}
      >
        <CardTitle>
          <Pane
            is="div"
            className="flex h-10 w-full rounded-md border border-input bg-background px-3 pr-8 py-2 text-sm ring-offset-background relative"
          >
            <p className="w-full truncate">{`${baseUrl}${router.pathname}`}</p>
            <Button
              className="w-6 h-6 absolute right-1 top-[50%] translate-y-[-50%] rounded"
              variant="outline"
              size="icon"
              onClick={onCopy}
            >
              <Copy className="h-3 w-3" />
            </Button>
          </Pane>
        </CardTitle>
        <CardDescription>{t("action.share-tip")}</CardDescription>
      </CardHeader>
      <CardContent
        className={clsx("grid gap-2", {
          "p-3": isNavigator
        })}
      >
        <Pane is="div" className="flex space-x-3">
          <Button
            className="rounded w-8 h-8"
            variant="outline"
            size="icon"
            onClick={() => onShare({ type: "x" })}
          >
            <TwitterIcon className="w-4 h-4" />
          </Button>
          <Button
            className="rounded w-8 h-8"
            variant="outline"
            size="icon"
            onClick={() => onShare({ type: "facebook" })}
          >
            <FacebookIcon className="w-4 h-4" />
          </Button>
        </Pane>
      </CardContent>
      {/* <CardFooter className="flex-col space-y-4"></CardFooter> */}
    </Card>
  );
}
