/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    locales: ["en", "zh", "jp", "kr", "ru", "es"],
    defaultLocale: "en",
    localeDetection: false
  },
  fallbackLng: {
    default: ["en"]
  },
  localePath:
    typeof window === "undefined"
      ? require("path").resolve("./public/locales")
      : "/locales",
  reloadOnPrerender: process.env.NODE_ENV === "development"
};
