import * as React from "react";
import Link from "next/link";
import { navigatorMenus } from "@utils/routes";
import { useRouter } from "next/router";

import { cn } from "@utils/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from "@/components/ui/navigation-menu";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

export default function NavigationMenuDemo() {
  const { t: tc } = useTranslation("common");
  const { t } = useTranslation(["description"]);
  const { t: tt } = useTranslation("title");

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {Object.keys(navigatorMenus).map(key => {
          return (
            <NavigationMenuItem key={key}>
              <NavigationMenuTrigger>{tc(`tool.${key}`)}</NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul
                  className={clsx("grid w-[400px] gap-3 p-4", {
                    ["w-[800px] grid-cols-5 md:w-[900px] md:grid-cols-6 lg:w-[1000px] "]:
                      key === "JSON Tool",
                    ["md:w-[500px] md:grid-cols-2 lg:w-[600px] "]:
                      key !== "JSON Tool"
                  })}
                >
                  {navigatorMenus[key]?.length &&
                    navigatorMenus[key].map(component => {
                      let title = tt(component?.desc);
                      if (title === component.desc) {
                        title = component.label;
                      }
                      if (title.startsWith("to")) {
                        title = `${key.replace(" Tool", "")} ${title}`;
                      }

                      return (
                        <ListItem
                          key={`${key}-${title}-${component.path}`}
                          title={title}
                          href={component.path}
                        >
                          {component?.desc
                            ? t(component?.desc)
                            : `An online playground to convert ${component.label}`}
                        </ListItem>
                      );
                    })}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          );
        })}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          title={title}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
