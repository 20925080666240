import React, { Fragment } from "react";
import { Heading, Pane, Text } from "evergreen-ui";
import { categorizedRoutes, Route } from "@utils/routes";
import Link from "next/link";
import { useRouter } from "next/router";
import SearchBox from "@components/Searchbox";
import ShareCard from "@components/ShareCard";
import { useTranslation } from "react-i18next";

export default function Navigator() {
  const router = useRouter();
  const { t } = useTranslation("title");
  return (
    <Pane
      width={240}
      height={"calc(100vh - 50px)"}
      borderRight
      flexDirection="column"
      paddingTop={20}
      backgroundColor="#FFFFFF"
      className="hidden md:flex"
    >
      <Pane paddingX={15} marginBottom={10}>
        <SearchBox />
      </Pane>

      <Pane
        display="flex"
        flex={1}
        overflowY="scroll"
        flexDirection="column"
        paddingBottom={10}
      >
        {categorizedRoutes.map(route => {
          return (
            <Pane key={route.category}>
              <Pane paddingX={10} marginTop={15} marginBottom={2}>
                <Heading marginLeft={5} size={400}>
                  {route.category}
                </Heading>
              </Pane>

              {(route.content as Route[])
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((a: Route) => {
                  const isActive = router.pathname === a.path;
                  const title = t(a.desc) !== a.desc ? t(a.desc) : a.label;
                  return (
                    <Link
                      key={a.label}
                      href={a.path}
                      prefetch={false}
                      style={{
                        textDecoration: "none"
                      }}
                    >
                      <Pane
                        paddingLeft={16}
                        paddingY={3}
                        backgroundColor={isActive ? "#f3f3f3" : undefined}
                        borderLeft={
                          isActive
                            ? "3px solid #009688"
                            : "3px solid transparent"
                        }
                        className="hover_panel"
                      >
                        <Text fontSize={13}>{title}</Text>
                      </Pane>
                    </Link>
                  );
                })}
            </Pane>
          );
        })}
      </Pane>
      <Pane display="flex" overflowY="scroll" flexDirection="column">
        <ShareCard isNavigator />
      </Pane>
    </Pane>
  );
}
