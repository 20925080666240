import { routes } from "@utils/routes";
import { Autocomplete, SearchInput } from "evergreen-ui";
import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const SearchBox: React.FunctionComponent<{}> = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const onSearchSelect = useCallback(changedItem => {
    const route = routes.find(route => changedItem === route.searchTerm);
    const path = `${router.locale === "en" ? "" : `/${router.locale}`}${
      route.path
    }`;
    router.push(path);
  }, []);
  const AutoComplete = Autocomplete as any;

  return (
    <AutoComplete
      onChange={onSearchSelect}
      items={routes.map(a => a.searchTerm)}
    >
      {props => {
        const { getInputProps, getRef, inputValue } = props;
        return (
          <SearchInput
            width="100%"
            placeholder={t("action.search-placeholder")}
            value={inputValue}
            ref={getRef}
            {...getInputProps()}
          />
        );
      }}
    </AutoComplete>
  );
};

export default SearchBox;
